<script>
import { format, isPast, parseISO } from 'date-fns';
import { resize } from './resize'

import './style/index.css'
import { DateTime } from 'luxon';

const KUSTOMER_PADDING = 10;
const FORMHOOK_URL = 'https://api.kustomerapp.com/v1/hooks/form/5d6924c333488900191e6a78/315266a3ccad8cdc5cf3951a4f181025d0b9d1c6b54745dac0f1ead517837a58';

let reservations = [];
let formElement;
let loading = false;
let selectedReservationId;
let schedule = getCurrentISODate();
let note = '';
let selectedLocation;
let customerId;
let currentUser;
let cachingEnabled = false;

const locations = [
  'Bathroom',
  'Bedroom',
  'Dining Room',
  'Kitchen',
  'Living Room',
  'Patio/Balcony'
]

const isPastReservation = condition => ({ attributes: { custom } }) => isPast(parseISO(custom.checkOutDateLocalAt)) === condition;
const sortReservation = isAsc => (a, b) => a.attributes.custom.checkInDateLocalAt > b.attributes.custom.checkInDateLocalAt === isAsc;

const getReservationDescription = ({ attributes: { custom } }) =>
  `${formatDate(custom.checkInDateLocalAt)} - ${formatDate(custom.checkOutDateLocalAt)}, ${custom.confirmationCodeStr}`;

const getReservationOption = reservation => ({
  label: getReservationDescription(reservation),
  value: reservation.id,
});

$: reservationOptions = [
  ...reservations.filter(isPastReservation(false)).sort(sortReservation(true)).map(getReservationOption),
  ...reservations.filter(isPastReservation(true)).sort(sortReservation(false)).map(getReservationOption),
];

$: formValid = selectedReservationId && selectedLocation && schedule;

$: (() => {
  if (!cachingEnabled) {
    return;
  }
  const fields = {
    selectedReservationId,
    schedule,
    note,
  };
  sessionStorage.setItem(`customer-${customerId}`, JSON.stringify(fields));
})();

$: noteHeight = 10

function getCurrentISODate() {
  return format(new Date(), 'yyyy-MM-dd')
}

function getCurrentISODateTimeInResTZ(reservation) {
  return DateTime.now().setZone(reservation.attributes?.custom?.timezoneStr || 'utc').toISO();
}

function formatDate(date) {
  const parsedDate = parseISO(date);
  return format(parsedDate, `MM/dd${parsedDate.getFullYear() !== new Date().getFullYear() ? `/yy` : ''}`);
}

function showError(err, message) {
  console.error(err);
  if (message) {
    alert(message);
  }
}

function setFormData(data = {}) {
  selectedReservationId = data.selectedReservationId || '';
  selectedLocation = data.selectedLocation || '';
  schedule = getCurrentISODate();
  if (data.note) {
    note = data.note;
  }
}

function loadDataFromCache() {
  try {
    const cache = JSON.parse(sessionStorage.getItem(`customer-${customerId}`));
    setFormData(cache)
  } catch (e) {
    showError(e);
  }
}

function initialize() {
  resizeDynamicCard();
  if (reservations.length) {
    return
  }
  try {
    Kustomer.initialize(function (contextJSON) {
      customerId = contextJSON.customer.id;
      loadDataFromCache(customerId);
      cachingEnabled = true;

      Kustomer.request(
        { url: '/v1/users/current', method: 'get' },
        (err, user) => {
          if (err) {
            showError(err, 'Breezeway task widget :: Failed loading user.\nPlease refresh this page to retry.');
            return;
          }
          currentUser = user;
        }
      )

      Kustomer.request(
        {
          url: `/v1/customers/${customerId}/klasses/reservation`,
          method: 'get'
        },
        function (err, results) {
          if (err || !results) {
            showError(err, 'Breezeway task widget :: Failed loading the list of reservations.\nPlease refresh this page to retry.');
            return;
          }
          reservations = results;
          /*
			filter by date: only ongoing or future
			filter by status: only confirmed, checked_in, reserved
			reservations = res.filter(reservation => {
				return true;
			});
			sort by docs
			reservations.sort();
			*/
          if (reservations.length === 0) {
            Kustomer.close();
          }
        }
      );
    });
  } catch (error) {
    showError(error);
  }
}

function submitToHook() {
  loading = true;
  const reservation = reservations.find(({ id }) => id === selectedReservationId);

  if (!reservation.attributes?.custom?.unitStr) {
    showError('', `Cannot create breezeway task: selected reservation ${reservation.attributes?.custom?.confirmationCodeStr} is unassigned!`)
    loading = false;
    return;
  }

  schedule = getCurrentISODateTimeInResTZ(reservation);
  const formTask = {
    title: `Request: ${selectedLocation} - ${format(new Date(), 'MM/dd/yyyy')}`,
    notes: note,
    description: note,
    bwDescription: note,
    reservationKustomerId: selectedReservationId,
    reservationDetails: getReservationDescription(reservation),
    location: selectedLocation,
    expectedCompletion: schedule,
    scheduleAt: schedule,
    createdById: currentUser?.id,
    createdBy: currentUser?.attributes?.name,
    reservationId: reservation.attributes?.externalId,
    reservationConfirmationCode: reservation.attributes?.custom?.confirmationCodeStr,
    unitInternalTitle: reservation.attributes?.custom?.unitStr,
    customerId,
  };

  fetch(FORMHOOK_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formTask),
    }).then(response => {
      formElement.reset();
      formElement.querySelectorAll('select').forEach(el => el.selectedIndex = 0);
      note = ''
      setFormData();
      return response.json()
    }).catch(error => {
      showError(error, 'There was an error while trying to submit this task.\nPlease try again.\nIf it keeps failing, please contact TechOps.');
    }).finally(() => {
      loading = false;
    });
}

function resizeDynamicCard() {
  if (typeof Kustomer !== 'undefined') {
    Kustomer.resize({ height: document.documentElement.scrollHeight + KUSTOMER_PADDING });
  }
}
</script>

<svelte:head>
  <script src="https://cdn.kustomerapp.com/card-js/latest/kustomer-card.min.js"></script>
</svelte:head>

<svelte:window on:load={initialize} />

<main>
  <form bind:this={formElement} on:submit|preventDefault={submitToHook}>
    <label for="select-reservation">Reservation</label>
    <select
      id="select-reservation"
      name="reservationId"
      required="required"
      bind:value={selectedReservationId}
    >
      <option value="" disabled selected hidden>Choose an option</option>
      {#each reservationOptions as { label, value }}
        <option {value}>{label}</option>
      {/each}
    </select>

    <label for="select-location">Location</label>
    <select
      id="select-location"
      name="locationStr"
      required="required"
      bind:value={selectedLocation}
    >
      <option value="" disabled selected hidden>Choose an option</option>
      {#each locations as value}
        <option {value}>{value}</option>
      {/each}
    </select>

    <label for="textarea-note">What is the request? (Be as detailed as possible!)</label>
    <textarea
      bind:value={note}
      id="textarea-note"
      name="description"
      rows={noteHeight}
      use:resize
      on:resize={() => resizeDynamicCard()}
    />

    <button disabled={!formValid || loading} type="submit">
      {loading ? 'Submitting...' : 'Submit'}
    </button>
  </form>
</main>

<style>
</style>
